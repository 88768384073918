import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./EditButton.module.css";
import editSvg from "./edit.svg";

/**
 * Interactive component. A button that allows the user to reset their vote on a decision item.
 */
export function EditButton({ onClick }) {
  return (
    <button onClick={onClick} className={classNames(styles.container)}>
      <div className={styles.btn}>
        <img src={editSvg} className={classNames(styles.image)} />

        <div className={classNames(styles.text)}>Edit</div>
      </div>
    </button>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
};
