import React, { useState } from "react";
import { Select } from "../../../shared/Select/Select";

import { Button } from "../../../shared/Button/Button";
import styles from "./RunReports.module.css";

export function RunReports({
  onRun,
  eventTypeOptions,
  eventOptions,
  yearOptions,
  reportOptions,
}) {
  const [reportData, setReportData] = useState({
    eventType: "",
    event: "",
    year: "",
    report: "",
  });

  const handleEventTypeSelect = (selection) => {
    setReportData({
      eventType: selection || "",
      event: "",
      year: "",
      report: "",
    });
  };

  const handleEventSelect = (selection) => {
    setReportData((prevState) => ({ ...prevState, event: selection || "" }));
  };

  const handleYearSelect = (selection) => {
    setReportData((prevState) => ({ ...prevState, year: selection || "" }));
  };

  const handleReportSelect = (selection) => {
    setReportData((prevState) => ({ ...prevState, report: selection || "" }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.menuRow}>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Event Type</label>
          <div className={styles.selectSpan}>
            <Select
              options={eventTypeOptions}
              placeholder={reportData.eventType || "Select"}
              onChange={handleEventTypeSelect}
            />
          </div>
        </div>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Event</label>
          <div className={styles.selectSpan}>
            <Select
              options={eventOptions}
              placeholder={reportData.event || "Select"}
              onChange={handleEventSelect}
            />
          </div>
        </div>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Year</label>
          <div className={styles.selectSpan}>
            <Select
              options={yearOptions}
              placeholder={reportData.year || "Select"}
              onChange={handleYearSelect}
            />
          </div>
        </div>

        <div className={styles.selectDiv}>
          <label className={styles.label}>Report</label>
          <div className={styles.selectSpan}>
            <Select
              options={reportOptions}
              placeholder={reportData.report || "Select"}
              onChange={handleReportSelect}
            />
          </div>
        </div>

        <div className={styles.button}>
          <Button
            className={styles.btnEnabled}
            children="RUN"
            onClick={() => onRun(reportData)}
          />
        </div>
      </div>
    </div>
  );
}
