import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import styles from "./PublishingV1InfoForm.module.css";
import Checkbox from "shared/@forms/Checkbox";
import InputItem from "Events/Components/InputItem";
import Select from "Events/Components/Select";
import Loader from "shared/Loader";

//Area to fill out the publishing information
export function PublishingV1InfoForm({
  publishingInfo,
  onPublishChange,
  pageMode,
}) {
  const [checkboxStates, setCheckboxStates] = useState({});
  const [inputValues, setInputValues] = useState({});
  const initialInputValuesRef = useRef({});
  const [loadingValue, setLoadingValue] = useState({});
  const [bookingStatusSelected, setBookingStatusSelected] = useState("");
  useEffect(() => {
    console.log(bookingStatusSelected);
  }, [bookingStatusSelected]);
  const isRulesetLocal = publishingInfo.event.ruleset.value;

  // Clears Input Fields whn Couting tickets is deselected
  // useEffect(() => {
  //   if (
  //     checkboxStates?.analytics?.countingTickets == "" &&
  //     (inputValues?.estimatedAttendance !== "" ||
  //       inputValues?.platinum !== "" ||
  //       inputValues?.purchase !== "")
  //   ) {
  //     onPublishChange({
  //       path: "analytics.sales.capacity.purchase",
  //       value: null,
  //     });

  //     onPublishChange({
  //       path: "analytics.sales.capacity.platinum",
  //       value: null,
  //     });

  //     onPublishChange({
  //       path: "analytics.sales.capacity.estimatedAttendance",
  //       value: null,
  //     }).finally(() => {
  //       setLoadingValue((prev) => ({
  //         ...prev,
  //         "analytics.countingTickets": false,
  //       }));
  //     });
  //   }
  // }, [checkboxStates]);

  useEffect(() => {
    const bookingStatus = publishingInfo?.marketing?.bookingStatus;
    setBookingStatusSelected(
      bookingStatus?.saved ??
        bookingStatus?.value ??
        (typeof bookingStatus === "string" ? bookingStatus : "")
    );
  }, [publishingInfo]);

  useEffect(() => {
    setCheckboxStates({
      marketing: {
        visibleCalendar:
          publishingInfo?.marketing?.visibleCalendar?.saved ??
          publishingInfo?.marketing?.visibleCalendar?.value ??
          "",
        tentative:
          publishingInfo?.marketing?.tentative?.saved ??
          publishingInfo?.marketing?.tentative?.value ??
          "",
      },
      analytics: {
        countingTickets:
          publishingInfo?.analytics?.countingTickets?.saved ??
          publishingInfo?.analytics?.countingTickets?.value ??
          "",
        sales: {
          soldOut:
            publishingInfo?.analytics?.sales?.soldOut?.saved ??
            publishingInfo?.analytics?.sales?.soldOut?.value ??
            "",
        },
      },
    });
  }, [publishingInfo]);

  useEffect(() => {
    setInputValues({
      purchase:
        publishingInfo?.analytics?.sales.capacity?.purchase?.saved ??
        publishingInfo?.analytics?.sales.capacity?.purchase?.value ??
        (typeof publishingInfo?.analytics?.sales.capacity?.purchase === "string"
          ? publishingInfo.analytics.sales.capacity.purchase
          : ""),

      platinum:
        publishingInfo?.analytics?.sales.capacity?.platinum?.saved ??
        publishingInfo?.analytics?.sales.capacity?.platinum?.value ??
        (typeof publishingInfo?.analytics?.sales.capacity?.platinum === "string"
          ? publishingInfo.analytics.sales.capacity.platinum
          : ""),

      estimatedAttendance:
        publishingInfo?.analytics?.sales.capacity?.estimatedAttendance?.saved ??
        publishingInfo?.analytics?.sales.capacity?.estimatedAttendance?.value ??
        (typeof publishingInfo?.analytics?.sales.capacity
          ?.estimatedAttendance === "string"
          ? publishingInfo.analytics.sales.capacity.estimatedAttendance
          : ""),
    });
  }, [publishingInfo]);

  const handleCheckboxChange = (section, field, isChecked) => {
    const newStates = { ...checkboxStates };

    const fullPath =
      section === "analytics" && field === "soldOut"
        ? "analytics.sales.soldOut"
        : `${section}.${field}`;

    const newSavedValue = isChecked ? "true" : "false";

    // Immediately update UI
    newStates[section][field] = {
      ...newStates[section][field],
      value: isChecked,
      saved: newSavedValue,
    };
    setCheckboxStates(newStates);

    setLoadingValue((prev) => ({ ...prev, [fullPath]: true }));
    onPublishChange({ path: fullPath, value: newSavedValue })
      .then(() => {
        // Confirm the change was successful and update the saved state
        setCheckboxStates((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            [field]: {
              ...prev[section][field],
              saved: newSavedValue,
            },
          },
        }));
      })
      .catch((error) => {
        console.error("API call failed for", fullPath, error);
        // Revert the checkbox state in case of an error
        setCheckboxStates((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            [field]: {
              ...prev[section][field],
              value: !isChecked,
              saved: !isChecked ? "true" : "false",
            },
          },
        }));
      })
      .finally(() => {
        setLoadingValue((prev) => ({ ...prev, [fullPath]: false }));
      });
  };

  const handleInputChange = (key, newValue) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  };

  const handleInputFocus = (key) => {
    initialInputValuesRef.current[key] = inputValues[key] ?? "";
  };

  const handleInputBlur = (key) => {
    const currentValue = inputValues[key];
    const initialValue = initialInputValuesRef.current[key];
    if (currentValue !== initialValue) {
      setLoadingValue((prev) => ({ ...prev, [key]: true }));

      const updatePath = `analytics.sales.capacity.${key}`;
      // Ensure that onPublishChange returns a promise
      onPublishChange({ path: updatePath, value: currentValue })
        .catch((error) => {
          console.error("API call failed for", key, error);
        })
        .finally(() => {
          setLoadingValue((prev) => ({
            ...prev,
            [key]: false,
          }));
        });
    }
  };

  const handleBookingStatusChange = (newValue) => {
    if (newValue !== bookingStatusSelected) {
      onPublishChange({
        path: "marketing.bookingStatus",
        value: newValue,
      })
        .catch((error) => {
          console.error("API call failed for Booking Status", error);
        })
        .finally(() => {
          setBookingStatusSelected(newValue);
        });
    }
  };

  const renderCheckbox = (section, fieldIdentifier, onChange, key) => {
    const field =
      section === "marketing"
        ? publishingInfo?.marketing[fieldIdentifier]
        : section === "analytics" && fieldIdentifier === "soldOut"
        ? publishingInfo?.analytics?.sales[fieldIdentifier]
        : publishingInfo?.analytics[fieldIdentifier];

    const checkboxState = checkboxStates[`${section}.${fieldIdentifier}`];
    const isLoading = loadingValue[`${section}.${fieldIdentifier}`];

    const fallbackChecked =
      checkboxState?.value ?? field?.saved ?? field?.value ?? false;

    const checkedStatus =
      checkboxState !== undefined ? checkboxState : fallbackChecked;

    const permission = pageMode === "view" ? "read" : field?.permission;

    if (permission === "write" || permission === undefined) {
      return (
        <>
          <span className={styles.checkbox}>
            <Checkbox
              checked={checkedStatus}
              disabled={isLoading}
              onChange={(e) => {
                handleCheckboxChange(
                  section,
                  fieldIdentifier,
                  e.target.checked
                );
              }}
            />
          </span>
        </>
      );
    } else if (permission === "read") {
      return (
        <>
          <span className={styles.textValue}>
            {field === true || field?.saved === true || field?.value === true
              ? "Yes"
              : "No"}
          </span>
        </>
      );
    }
    if (permission === "hidden") {
      return null;
    }
  };

  const renderInput = (field, label, key) => {
    const permission = pageMode === "view" ? "read" : field?.permission;

    const isPlatinumDisabled =
      (key === "platinum" || key === "estimatedAttendance") &&
      loadingValue["purchase"];
    const inputValue = inputValues[key] ?? field?.saved ?? field?.value ?? 0;
    const isLoading = loadingValue[key];

    //Adjust alignment of capacity or attendance
    const labelStyle = (() => {
      if (label) {
        return {
          minWidth: "184px",
          display: "inline-block",
        };
      }
    })();

    if (permission === "read" || pageMode === "view") {
      return (
        <div className={styles.colValue}>
          <span className={styles.label} style={labelStyle}>
            {label}
          </span>
          <span className={styles.textInput}>{inputValue || 0}</span>
        </div>
      );
    }

    return (
      <>
        {/* {isLoading && !inputValue ? (
          <div className={styles.loader}>
            <div>
              <Loader />
            </div>
          </div>
        ) : ( */}
        <InputItem
          label={`${label}${field?.required == true ? " *" : ""}`}
          value={inputValue}
          // placeholder="0"
          onChange={(e) => handleInputChange(key, e.target.value)}
          onBlur={() => handleInputBlur(key)}
          onFocus={() => handleInputFocus(key)}
          className={isPlatinumDisabled ? styles.disabled : styles.inputItem}
          // disabled={isPlatinumDisabled}
          tabIndex={isPlatinumDisabled ? -1 : 0}
          style={{ cursor: isPlatinumDisabled ? "not-allowed" : "auto" }}
        />
        {/* )} */}
      </>
    );
  };

  const countingTicketsValue =
    publishingInfo?.analytics?.countingTickets?.saved ||
    publishingInfo?.analytics?.countingTickets?.value;
  const isCountingTicketsChecked =
    countingTicketsValue === true || countingTicketsValue === "Yes";

  return (
    <Card
      children={
        <section className={styles.container}>
          <div className={styles.title}>PUBLISHING INFORMATION</div>

          <hr className={styles.hr} />
          <div className={styles.row}>
            <div className={styles.col}>
              {pageMode == "edit" ? (
                <>
                  <span className={styles.label}>
                    Booking Status
                    {publishingInfo.marketing.bookingStatus?.required && " *"}
                  </span>
                  <Select
                    placeholder={bookingStatusSelected || "Select"}
                    value={bookingStatusSelected}
                    options={["Tentative", "Contracted", "Booked"]}
                    onChange={(value) => {
                      handleBookingStatusChange(value);
                    }}
                    className={styles.bookingStatus}
                  />
                </>
              ) : (
                <>
                  {console.log(bookingStatusSelected)}
                  <span className={styles.label}>Booking Status</span>
                  <div className={styles.bookingText}>
                    {bookingStatusSelected || "N/A"}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <span className={styles.label}>
                Visible on Calendar
                {publishingInfo.marketing.visibleCalendar?.required && " *"}
              </span>

              {renderCheckbox("marketing", "visibleCalendar", onPublishChange)}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.tentative}>
              <span className={styles.label}>
                Tentative
                {publishingInfo.marketing.tentative?.required && " *"}
              </span>
              {renderCheckbox("marketing", "tentative", onPublishChange)}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <span className={styles.label}>
                Sold Out
                {publishingInfo.analytics.sales.soldOut.required && " *"}
              </span>
              {renderCheckbox("analytics", "soldOut", onPublishChange)}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <span className={styles.label}>
                {publishingInfo.analytics.countingTickets.required
                  ? "Counting Tickets *"
                  : "Counting Tickets"}
              </span>

              {renderCheckbox("analytics", "countingTickets", onPublishChange)}
            </div>
          </div>

          {isRulesetLocal === "Local v1.0" ? (
            <>
              {isCountingTicketsChecked ? (
                <>
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.purchase,
                      "Purchase Capacity",
                      "purchase"
                    )}
                  </div>

                  {/* {loadingValue["purchase"] !== true && ( */}
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity
                        ?.estimatedAttendance,
                      "Estimated Attendance",
                      "estimatedAttendance"
                    )}
                  </div>
                  {/* )} */}
                </>
              ) : null}
            </>
          ) : (
            <>
              {isCountingTicketsChecked ? (
                <>
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.purchase,
                      "Purchase Capacity",
                      "purchase"
                    )}
                  </div>

                  {/* {loadingValue["purchase"] !== true && ( */}
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.platinum,
                      "Platinum Capacity",
                      "platinum"
                    )}
                  </div>
                  {/* )} */}
                </>
              ) : null}
            </>
          )}
        </section>
      }
    />
  );
}

PublishingV1InfoForm.propTypes = {
  //Value of visible on date
  visibleOnDate: PropTypes.string,
  //Used to change the visible on date
  setVisibleOnDate: PropTypes.func,
  //Value of visible on time
  visibleOnTime: PropTypes.string,
  //used to set the visible on time
  setVisibleOnTime: PropTypes.func,
  //value of purchase begin date
  purchaseBeginDate: PropTypes.string,
  //used to set the purchase begin date
  setPurchaseBeginDate: PropTypes.func,
  //value of the purchase begin time
  purchaseBeginTime: PropTypes.string,
  //used to set the purchase begin time
  setPurchaseBeginTime: PropTypes.func,
  //value of purchase end time
  purchaseEndTime: PropTypes.string,
  //used to set the purchase end time
  setPurchaseEndTime: PropTypes.func,
  //value of the purchase end date
  purchaseEndDate: PropTypes.string,
  //used to set the purchase end date
  setPurchaseEndDate: PropTypes.func,
  //value of visible to select
  visibleTo: PropTypes.string,
  //used to set the value of the visible to select
  setVisibleTo: PropTypes.func,
};
