import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./DateV1Form.module.css";
import Card from "shared/Card";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import {
  extractDate,
  extractTime,
  combineDateTime,
  extractDateTime,
} from "shared/@utils/extractCombineDateTime";

import { Select } from "shared/Select/Select";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment";

dayjs.extend(utc);
dayjs.extend(timezone);

export function DateV1Form({
  dateInfo,
  location,
  ruleset,
  eventName,
  onDateTimeChange,
  pageMode,
}) {
  const [dateInfoData, setDateInfoData] = useState({
    purchaseStartDate: "",
    purchaseEndDate: "",
    eventStartDate: "",
    eventEndDate: "",
    changeRequestStartDate: "",
    changeRequestEndDate: "",
    eagleStartDate: "",
    eagleStartDateTime: "",
    eagleEndDate: "",
    checkInStartDate: "",
    checkInEndDate: "",
    printReleaseDate: "",
    //local function > regional
    seminarDoorsOpenDate: "",
    seminarSessionStartDate: "",
    seminarSessionEndDate: "",
    slmDoorsOpenDate: "",
    slmSessionStartDate: "",
    slmSessionEndDate: "",
  });

  const [timeZoneData, setTimeZoneData] = useState({
    purchaseStartTimeZone: "Pacific Time (PT)",
    purchaseEndTimeZone: "Pacific Time (PT)",
    eventStartTimeZone: "Pacific Time (PT)",
    eventEndTimeZone: "Pacific Time (PT)",
    changeRequestStartTimeZone: "Pacific Time (PT)",
    changeRequestEndTimeZone: "Pacific Time (PT)",
    eagleStartTimeZone: "Pacific Time (PT)",
    eagleEndTimeZone: "Pacific Time (PT)",
    checkInStartTimeZone: "Pacific Time (PT)",
    checkInEndTimeZone: "Pacific Time (PT)",
    printReleaseTimeZone: "Pacific Time (PT)",

    //local function > regional
    seminarDoorsOpenTimeZone: "Pacific Time (PT)",
    seminarSessionStartTimeZone: "Pacific Time (PT)",
    seminarSessionEndTimeZone: "Pacific Time (PT)",
    slmDoorsOpenTimeZone: "Pacific Time (PT)",
    slmSessionStartTimeZone: "Pacific Time (PT)",
    slmSessionEndTimeZone: "Pacific Time (PT)",
  });

  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZoneData);

  const getTimeZoneAbbr = (timeZone) => {
    switch (timeZone) {
      case "America/Los_Angeles":
        return "Pacific Time (PT)";
      case "America/Boise":
        return "Mountain Time (MT)";
      case "America/Chicago":
        return "Central Time (CT)";
      case "America/New_York":
        return "Eastern Time (ET)";
      case "America/Anchorage":
        return "Alaska Time (AKT)";
      case "America/Adak":
        return "Hawaii-Aleutian Time (HAT)";
      case "Pacific/Honolulu":
        return "Hawaii-Honolulu (HST)";
      default:
        return "Pacific Time (PT)";
    }
  };

  const timeZones = [
    "Pacific Time (PT)",
    "Mountain Time (MT)",
    "Central Time (CT)",
    "Eastern Time (ET)",
    "Alaska Time (AKT)",
    "Hawaii-Aleutian Time (HAT)",
    "Hawaii-Honolulu (HST)",
  ];

  useEffect(() => {
    const newDateInfoData = { ...dateInfoData };

    Object.entries(dateInfo?.dates ?? {}).forEach(([key, dateObj]) => {
      const isDateField = key.endsWith("Date");
      const isTimeZoneField = key.endsWith("TimeZone");

      if (isDateField) {
        const dateValue =
          dateObj.saved !== null ? extractDate(dateObj.saved) : null;
        const timeValue =
          dateObj.saved !== null ? extractTime(dateObj.saved) : null;

        const dateKey = key;
        newDateInfoData[dateKey] = dateValue;

        const timeKey = key.replace("Date", "Time");
        newDateInfoData[timeKey] = timeValue;
      } else if (isTimeZoneField) {
        newDateInfoData[key] = dateObj.value || null;
      }
    });

    setDateInfoData(newDateInfoData);
  }, [dateInfo]);

  const [userHasSelectedTimeZone, setUserHasSelectedTimeZone] = useState(false);

  useEffect(() => {
    if (!userHasSelectedTimeZone && dateInfo?.dates) {
      const timezoneInfo =
        dateInfo?.dates[
          Object.keys(dateInfo?.dates ?? {}).find((key) =>
            key.endsWith("TimeZone")
          )
        ];
      const initialTimeZoneValue = timezoneInfo?.saved || timezoneInfo?.value;

      if (initialTimeZoneValue) {
        const initialTimeZoneLabel = getTimeZoneAbbr(initialTimeZoneValue);
        setSelectedTimeZone(initialTimeZoneLabel);
      }
    }
  }, [dateInfo, userHasSelectedTimeZone]);

  const handleDateTimeChange = (key, value, isTime = false) => {
    setDateInfoData((prevData) => {
      let newData = { ...prevData };

      if (isTime) {
        //Update the time, keep the date intact
        const dateKey = key.replace("Time", "Date");
        const existingDate = prevData[dateKey];

        if (existingDate) {
          // Ensure the existing date is correctly formatted
          const formattedDate = moment(existingDate, "MM/DD/YYYY").isValid()
            ? moment(existingDate, "MM/DD/YYYY").format("YYYY-MM-DD")
            : null;

          if (!formattedDate) {
            console.error("Invalid date format, skipping time update.");
            return prevData;
          }

          // Combine date and time, treat selected time as UTC (no timezone shift)
          const combinedDateTime = moment
            .utc(`${formattedDate} ${value}`, "YYYY-MM-DD HH:mm")
            .toISOString();

          onDateTimeChange(key, combinedDateTime, isTime);

          // Only update the time in state, keep the date intact
          newData[`${dateKey}Time`] = value;
        } else {
          console.error("Cannot update time without a valid selected date.");
          return prevData;
        }
      } else {
        const timeKey = key + "Time";
        const existingTime = prevData[timeKey] || "00:00";

        // Ensure the selected date is valid and correct input format ("MM/DD/YYYY")
        const formattedDate = moment(value, "MM/DD/YYYY").isValid()
          ? moment(value, "MM/DD/YYYY").format("YYYY-MM-DD")
          : null;

        if (!formattedDate) {
          console.error("Invalid date format, cannot update.");
          return prevData;
        }

        // Combine the new date with the time (or default "00:00")
        const combinedDateTime = moment
          .utc(`${formattedDate} ${existingTime}`, "YYYY-MM-DD HH:mm")
          .toISOString();

        onDateTimeChange(key, combinedDateTime, isTime);

        newData[key] = value;
        newData[timeKey] = existingTime;
      }

      return newData;
    });
  };

  const handleTimeZoneChange = (fieldKey, selectedOption) => {
    setUserHasSelectedTimeZone(true);

    setTimeZoneData((prevData) => ({
      ...prevData,
      [fieldKey]: selectedOption,
    }));

    if (typeof onDateTimeChange === "function") {
      onDateTimeChange(fieldKey, selectedOption, false, true);
    }
  };

  const renderPickerField = (fieldKey, label) => {
    const datePermission =
      pageMode === "view" ? "read" : dateInfo?.dates[fieldKey]?.permission;

    // Extract saved values or use current state
    const dateTimeValue =
      dateInfo?.dates[fieldKey]?.saved ||
      dateInfo?.dates[fieldKey]?.value ||
      dateInfoData[fieldKey] ||
      "";

    const dateKey = `${fieldKey}-date-${dateInfoData[fieldKey] || "null"}`;
    const timeKey = `${fieldKey}-time-${
      dateInfoData[fieldKey.replace("Date", "Time")] || "null"
    }`;

    if (datePermission === "read") {
      const displayValue = dateTimeValue
        ? extractDateTime(dateTimeValue)
        : "N/A";

      // Adjust timezone spacing if it's read
      const timezoneStyle = {
        marginRight: "-10px",
        display: "inline-block",
      };

      return (
        <div>
          {pageMode === "edit" ? (
            <span className={styles.dateText} style={timezoneStyle}>
              {displayValue}
            </span>
          ) : (
            <span className={styles.dateText}>{displayValue}</span>
          )}
        </div>
      );
    }

    // Render for "write" permission
    if (datePermission === "write") {
      return (
        <div className={styles.labelAndPickerContainer}>
          <>
            <DatePicker
              key={dateKey}
              dateString={
                dateInfoData[fieldKey]
                  ? dayjs(dateInfoData[fieldKey]).local().format("MM/DD/YYYY")
                  : extractDate(dateTimeValue)
              }
              setDate={(date) => handleDateTimeChange(fieldKey, date, false)}
            />
            <TimePicker
              key={timeKey}
              timeString={extractTime(dateTimeValue)}
              onChange={(time) => {
                handleDateTimeChange(fieldKey, time, true);
              }}
            />
          </>
        </div>
      );
    }

    if (datePermission === "hidden") {
      return null;
    }
  };

  const renderTimeZoneSelect = (fieldKey, label) => {
    const timezoneKey = `${fieldKey}TimeZone`;
    // const timezonePermission =
    //   pageMode === "view" ? "read" : dateInfo?.dates[timezoneKey]?.permission;

    const timezonePermission = "view"
      ? "read"
      : dateInfo?.dates[timezoneKey]?.permission;

    const dateKey = fieldKey + "Date";

    // Determine if the time doesn't exist don't show timezone
    const dateObj = dateInfo.dates[dateKey];
    const dateExists =
      dateObj && (dateObj.saved !== null || dateObj.value !== null);

    if (!dateExists) {
      return null;
    }

    const timezoneValue = timeZoneData[timezoneKey] || "Pacific Time (PT)";

    // Render for "read" permission
    if (timezonePermission === "read") {
      return (
        <div className={styles.timezoneText}>
          {getTimeZoneAbbr(dateInfo.dates[timezoneKey]?.value)}
        </div>
      );
    }

    // Render for "write" permission
    if (timezonePermission === "write") {
      return (
        <div className={styles.timezone}>
          <Select
            options={timeZones}
            value={timezoneValue}
            placeholder={timezoneValue}
            onChange={(selectedOption) =>
              handleTimeZoneChange(timezoneKey, selectedOption, false)
            }
            disable={false}
            className={styles.timezone}
          />
        </div>
      );
    }

    if (timezonePermission === "hidden") {
      return null;
    }
  };

  //Function to split the label
  const splitLabel = (label) => {
    if (label.includes("Starts On")) {
      return [label.replace(" Starts On", ""), "Starts On:"];
    } else if (label.includes("Ends On")) {
      return [label.replace(" Ends On", ""), "Ends On:"];
    }
    return [label];
  };

  const renderDateTimeSet = (
    startDateKey,
    startTimeKey,
    startLabel,
    endDateKey,
    endTimeKey,
    endLabel
  ) => {
    const [startDateLabel, suffixLabel1] = splitLabel(startLabel);
    const [endDateLabel, suffixLabel2] = endLabel ? splitLabel(endLabel) : [];

    const isRequired = dateInfo.dates[startDateKey]?.required;

    return (
      <div className={styles.dateTimeSet}>
        <div className={styles.rowContainer}>
          <div className={styles.labelContainer}>
            <label>
              {startDateLabel}
              {isRequired && pageMode === "edit" ? " *" : ""}
            </label>
          </div>

          {suffixLabel1 && (
            <div className={styles.suffixLabelContainer}>
              <label>{suffixLabel1}</label>
            </div>
          )}

          <div className={styles.labelAndPickerContainer}>
            <div className={styles.datePickerContainer}>
              {renderPickerField(startDateKey, startLabel)}
            </div>

            {pageMode === "edit" && (
              <div className={styles.timePickerContainer}>
                {renderPickerField(startTimeKey, startLabel)}
              </div>
            )}

            {dateInfo.dates && (
              <div className={styles.timezoneEdit}>
                {renderTimeZoneSelect(
                  startDateKey.replace("Date", ""),
                  `${startLabel} Timezone`
                )}
              </div>
            )}
          </div>
        </div>

        {/* Input Fields with Ends on */}
        {endDateKey && endTimeKey && endLabel && (
          <div className={styles.rowContainer}>
            <div className={styles.labelContainer}>
              <label className={styles.hiddenLabel}>
                {/* {label2} */}
                <label>{endDateLabel}</label>
                {pageMode === "edit"}
              </label>
            </div>

            {suffixLabel2 && (
              <div className={styles.suffixLabelContainer}>
                <label>{suffixLabel2}</label>
              </div>
            )}

            <div className={styles.labelAndPickerContainer}>
              <div className={styles.datePickerContainer}>
                {renderPickerField(endDateKey, endLabel)}
              </div>

              {pageMode === "edit" && (
                <div className={styles.timePickerContainer}>
                  {renderPickerField(endTimeKey, endLabel)}
                </div>
              )}

              <div className={styles.timezoneEdit}>
                {renderTimeZoneSelect(
                  endDateKey.replace("Date", ""),
                  `${endLabel} Timezone`
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  //Hide some of the date keys based on function and location
  const dateKeysToHide = [
    {
      hideKeys: () =>
        (ruleset === "Major v1.0" && location === "virtual") ||
        (ruleset === "LOS v1.0" && location === "virtual"),
      keysToHide: [
        "checkInStartDate",
        "checkInEndDate",
        "eagleStartDate",
        "eagleEndDate",
        "eventEndDate",
        "printReleaseDate",
      ],
    },
    {
      hideKeys: () =>
        ruleset === "Local v1.0" &&
        location === "onsite" &&
        eventName === "Second Look Meeting",

      keysToHide: [
        "changeRequestStartDate",
        "seminarDoorsOpenDate",
        "seminarSessionStartDate",
        "seminarSessionEndDate",
      ],
    },
    {
      hideKeys: () =>
        ruleset === "Local v1.0" &&
        location === "virtual" &&
        eventName === "Second Look Meeting",

      keysToHide: [
        "slmDoorsOpenDate",
        "slmSessionStartDate",
        "slmSessionEndDate",
        "seminarDoorsOpenDate",
        "seminarSessionStartDate",
        "seminarSessionEndDate",
      ],
    },
  ];

  let allKeysToHide = [];

  dateKeysToHide.forEach((dateKey) => {
    if (dateKey.hideKeys()) {
      allKeysToHide = allKeysToHide.concat(dateKey.keysToHide);

      const timeZoneKeys = dateKey.keysToHide.map((key) =>
        key.replace("Date", "TimeZone")
      );
      allKeysToHide = allKeysToHide.concat(timeZoneKeys);
    }
  });

  //Extract required dates from dateInfo
  const filteredDates = Object.keys(dateInfo.dates)
    .filter((key) => key.endsWith("Date"))
    .reduce((acc, key) => {
      const timeZoneKey = key.replace("Date", "TimeZone");

      if (allKeysToHide.includes(key)) {
        return acc;
      }

      if (dateInfo.dates[key].field !== null) {
        acc[key] = dateInfo.dates[key].field;

        if (
          dateInfo.dates[timeZoneKey] &&
          !allKeysToHide.includes(timeZoneKey)
        ) {
          acc[timeZoneKey] = dateInfo.dates[timeZoneKey].field;
        }
      }
      return acc;
    }, {});

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Function to extract base labels (ex. Events + Start On)
  const extractLabels = (dates) => {
    const labels = {};

    Object.keys(dates).forEach((key) => {
      let label = key
        ?.replace(/StartDate|EndDate|Date/g, "")
        ?.replace(/([A-Z])/g, " $1")
        .trim();
      // Add "Starts On" or "Ends On" b
      if (key.endsWith("StartDate")) {
        label += " Starts On";
      } else if (key.endsWith("EndDate")) {
        label += " Ends On";
      }

      labels[key] = capitalizeFirstLetter(label);
    });

    return labels;
  };

  const extractedLabels = extractLabels(filteredDates);

  //Split the labels into each date array
  const splitLabelsArray = (extractedLabels) => {
    const splitLabels = [];

    const dateKeys = Object.keys(extractedLabels).filter((key) =>
      key.endsWith("Date")
    );

    const processedKeys = new Set();

    dateKeys.forEach((currentDateKey) => {
      if (processedKeys.has(currentDateKey)) return;

      const currentTimeKey = currentDateKey.replace("Date", "Time");
      let currentLabel = extractedLabels[currentDateKey];

      // Special cases for label names
      if (currentDateKey === "printReleaseDate") {
        currentLabel = "Ticket Print Starts On";
      } else if (currentDateKey === "seminarDoorsOpenDate") {
        currentLabel = "Seminar Doors Starts On";
      } else if (currentDateKey === "slmDoorsOpenDate") {
        currentLabel = "SLM Doors Starts On";
      } else if (currentDateKey === "slmSessionStartDate") {
        currentLabel = "SLM Session Starts On";
      }

      const baseName = currentDateKey.replace(/StartDate|EndDate|Date/g, "");
      const isStartDate = currentDateKey.includes("StartDate");
      const isEndDate = currentDateKey.includes("EndDate");

      let pairedDateKey = "";
      let pairedTimeKey = "";
      let pairedLabel = "";

      // Find the start & end dates
      if (isStartDate) {
        pairedDateKey = dateKeys.find(
          (key) => key.startsWith(baseName) && key.endsWith("EndDate")
        );
      } else if (isEndDate) {
        pairedDateKey = dateKeys.find(
          (key) => key.startsWith(baseName) && key.endsWith("StartDate")
        );
      }

      // Check if the start & end date exists
      if (pairedDateKey && !processedKeys.has(pairedDateKey)) {
        pairedTimeKey = pairedDateKey.replace("Date", "Time");
        pairedLabel = extractedLabels[pairedDateKey];
        processedKeys.add(pairedDateKey);
      }

      splitLabels.push([
        currentDateKey,
        currentTimeKey,
        currentLabel,
        pairedDateKey || "",
        pairedTimeKey || "",
        pairedLabel || "",
      ]);
      processedKeys.add(currentDateKey);
    });

    return splitLabels;
  };

  const dateFields = splitLabelsArray(extractedLabels);

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <div className={styles.cardHeader}>DATES</div>
      </div>
      <hr className={styles.hr} />

      <div>
        {dateFields &&
          Array.isArray(dateFields) &&
          dateFields.length > 0 &&
          dateFields.map(
            ([
              startDateKey,
              startTimeKey,
              startLabel,
              endDateKey,
              endTimeKey,
              endLabel,
            ]) => (
              <div
                key={`${startDateKey}-${startTimeKey}-${endDateKey}-${endTimeKey}`}
              >
                {renderDateTimeSet(
                  startDateKey,
                  startTimeKey,
                  startLabel,
                  endDateKey,
                  endTimeKey,
                  endLabel
                )}
              </div>
            )
          )}
      </div>
    </Card>
  );
}

DateV1Form.propTypes = {
  ruleset: PropTypes.string,
  location: PropTypes.string,
  eventName: PropTypes.string,
};
