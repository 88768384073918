import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ChevronDown } from "react-ikonate";
import styles from "./EditSelect.module.css";
import classNames from "classnames";

export function EditSelect({
  options,
  onChange,
  placeholder,
  disabled,
  dropdownRight = false,
  className,
  isScrollable,
  onBlur,
  isSearchable,
  label,
}) {
  const [dropdownDisplay, changeDropDownDisplay] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValue, setSelectedValue] = useState(placeholder);
  useEffect(() => {
    setSelectedValue(placeholder);
  }, [placeholder]);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          changeDropDownDisplay(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (dropdownDisplay) {
      let filtered = options;
      if (searchTerm && isSearchable) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        const startsWithSearchTerm = filtered.filter(
          (option) =>
            option.toLowerCase().startsWith(lowerSearchTerm) &&
            option.toLowerCase() !== "all"
        );
        const containsSearchTerm = filtered.filter(
          (option) =>
            !option.toLowerCase().startsWith(lowerSearchTerm) &&
            option.toLowerCase().includes(lowerSearchTerm) &&
            option.toLowerCase() !== "all"
        );

        filtered = [
          ...startsWithSearchTerm.sort(),
          ...containsSearchTerm.sort(),
          ...filtered.filter(
            (option) =>
              !option.toLowerCase().includes(lowerSearchTerm) &&
              option.toLowerCase() !== "all"
          ),
        ];
      } else {
        filtered = options;
      }
      setFilteredOptions(filtered);
    } else {
      setSearchTerm("");
      setFilteredOptions(options);
    }
  }, [searchTerm, dropdownDisplay, options, isSearchable]);

  useEffect(() => {
    if (dropdownDisplay && isSearchable) {
      const handleKeyPress = (event) => {
        if (event.key === "Backspace") {
          setSearchTerm((term) => term.slice(0, -1));
        } else if (event.key.length === 1) {
          setSearchTerm((term) => term + event.key);
        }
      };

      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [dropdownDisplay, isSearchable]);

  return (
    <div ref={wrapperRef} className={styles.container}>
      <div className={styles.label}>{label}</div>
      <button
        className={classNames(styles.selectDropdown, className)}
        onClick={() => changeDropDownDisplay(!dropdownDisplay)}
        disabled={disabled}
      >
        <span>{selectedValue}</span>
        <span className={styles.selectChevron}>
          <ChevronDown fontSize="1.4em" />
        </span>
      </button>
      {dropdownDisplay && (
        <div
          className={classNames(
            dropdownRight ? styles.selectOptionsRight : styles.selectOptions,
            { [styles.scrollable]: isScrollable }
          )}
        >
          {filteredOptions.map((option) => (
            <button
              className={styles.selectMenuOption}
              key={option}
              onClick={() => {
                setSelectedValue(option);
                onChange(option);
                changeDropDownDisplay(false);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

EditSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dropdownRight: PropTypes.bool,
  className: PropTypes.string,
  isScrollable: PropTypes.bool,
  onBlur: PropTypes.func,
  isSearchable: PropTypes.bool,
};

EditSelect.defaultProps = {
  disabled: false,
  isSearchable: true,
};
