//Format field name in undo changes
export const formatFieldName = (fieldName) => {
  const fieldMappings = {
    platinum: "PLATINUM CAPACITY",
    purchase: "PURCHASE CAPACITY",
    visibleCalendar: "VISIBLE ON CALENDAR",
  };

  if (fieldMappings[fieldName]) {
    return fieldMappings[fieldName];
  }

  return fieldName.replace(/([a-z])([A-Z])/g, "$1 $2");//.toUpperCase();
};

export default formatFieldName;
