import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Auth } from "shared/@auth/Auth";
import { createContainer } from "unstated-next";

export function useRefundOrder() {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();

  const refundOrderMutation = useMutation(
    async ({ iboNumber, orderId, year, amountPaid, amountRefunding, ccId, tickets, addFee }) => {
      const response = await makeAuthedRequest(`v1/events/order/refund`, {
        method: "PUT",
        body: JSON.stringify({ iboNumber, orderId, year, amountPaid, amountRefunding, ccId, tickets, addFee }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 204 || response.status === 200) {
        return { message: amountRefunding === 0 ? "Cancellation successful!" : "Refund successful!", status: response.status };
      } else if (response.status === 409) {
        return {
          message: "Error refunding order.",
          status: response.status,
        };
      } else {
        const errorText = await response.text();
        let errorMessage = "An error occurred. Please try again later.";

        try {
          const errorBody = JSON.parse(errorText);
          errorMessage = errorBody.error?.message?.message || errorMessage;
        } catch (e) {
          console.error("Failed to parse error response:", e);
        }

        return {
          message: errorMessage,
          status: response.status,
        };
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("orders");
      },
    }
  );

  return refundOrderMutation;
}

export const RefundOrderContainer = createContainer(useRefundOrder);
