import React from "react";
import PropTypes from "prop-types";
import styles from "./AddressCard.module.css";
import AddressInfoItem from "../AddressInfoItem";
import { EditButton } from "../EditButton/EditButton";
import Button from "shared/Button";

/**
 * Interactive component
 *
 * Displays suggested or original address with option to Use Suggestion or Edit.
 */

export function AddressCard({
  original,
  address,
  title,
  onClickUse,
  onClickEdit,
}) {
  if (original) {
    return (
      <div className={styles.containerOriginal}>
        <div className={styles.title}>{title}</div>
        <div className={styles.address}>
          <AddressInfoItem
            address={address}
            labelColor={"fff"}
            isInModal={true}
            isSuggested={false}
          />
        </div>
        <div className={styles.bottomRow}>
          <button className={styles.useAddressButton} onClick={onClickUse}>
            Use Original Address
          </button>
          <div className={styles.buttonEdit}>
            <EditButton onClick={onClickEdit} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.containerSuggested}>
        <div className={styles.title}>{title}</div>
        <div className={styles.address}>
          <AddressInfoItem
            address={address}
            labelColor={"fff"}
            isInModal={true}
            isSuggested={true}
          />
        </div>
        <div className={styles.bottomRow}>
          <button className={styles.useSuggestedButton} onClick={onClickUse}>
            Use Suggested Address
          </button>
        </div>
      </div>
    );
  }
}

AddressCard.propTypes = {
  address: PropTypes.shape({
    recipient: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }),
  suggestedAddress: PropTypes.shape({
    recipient: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }),
  title: PropTypes.string,
  original: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickUse: PropTypes.func,
};

export default AddressCard;
