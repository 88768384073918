import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "./ViewLOSV1EventManagePage.module.css";
import Loader from "shared/Loader";

import { useViewEvents } from "./useViewEvents";
import { VenueCard } from "Events/Components/VenueCard/VenueCard";
import Header from "shared/Header";
import EventTypeCard from "Events/V1Components/EventTypeCard";
import EventV1InfoForm from "Events/V1Components/EventV1InfoForm";
import PublishingV1InfoForm from "Events/V1Components/PublishingV1InfoForm";
import DateV1Form from "Events/V1Components/DateV1Form";
import NotesV1Form from "Events/V1Components/NotesV1Form";
import TicketingV1Form from "Events/V1Components/TicketingV1Form";
import Button from "shared/Button";
import VenueV1Form from "Events/V1Components/VenueV1Form";

export function ViewLOSV1EventManagePage() {
  let history = useHistory();
  const { eventId } = useParams();

  const {
    data: viewEventData,
    isError,
    isLoading,
    refetch,
  } = useViewEvents(eventId);

  const [eventInfo, setEventInfo] = useState({
    event: {
      name: "",
      ruleset: "",
      location: "",
      dates: {},
      country: "",
      city: "",
      state: "",
      notes: "",
    },
    marketing: {
      title: "",
      description: "",
      tentative: "",
      visibleCalendar: "",
      bookingStatus: "",
      notes: "",
    },
    analytics: {
      sales: {
        soldOut: "",
        capacity: {
          purchase: 0,
          platinum: 0,
        },
      },
      countingTickets: "",
    },
    venue: {},
    tickets: [],
  });

  useEffect(() => {
    refetch();
  }, [eventId, refetch]);

  useEffect(() => {
    if (viewEventData && !isLoading) {
      setEventInfo({
        event: {
          name: viewEventData.event?.name || "",
          ruleset: viewEventData.event?.ruleset || "",
          location: viewEventData.event?.location || "",
          country: viewEventData?.event?.country || "",
          city: viewEventData?.event?.city || "",
          state: viewEventData?.event?.state || "",
          dates: viewEventData?.event?.dates || "",
          notes: viewEventData?.event?.notes || "",
        },

        marketing: {
          title: viewEventData?.marketing?.title.value || "",
          description: viewEventData.marketing?.description.value || "",
          tentative: viewEventData.marketing?.tentative || "",
          visibleCalendar: viewEventData.marketing?.visibleCalendar || "",
          bookingStatus: viewEventData.marketing?.bookingStatus,
          notes: viewEventData?.marketing?.notes || "",
        },
        analytics: {
          countingTickets: viewEventData.analytics?.countingTickets || "",
          sales: {
            soldOut: viewEventData.analytics?.sales?.soldOut || "",
            capacity: {
              purchase: viewEventData.analytics?.sales?.capacity?.purchase || 0,
              platinum: viewEventData.analytics?.sales?.capacity?.platinum || 0,
            },
          },
        },
        venue: {
          company: viewEventData?.venue?.company || {},
          room: viewEventData?.venue?.room || {},
        },
        tickets: viewEventData.tickets?.value || [],
      });
    }
  }, [viewEventData, isLoading]);

  const handleEditEvent = () => {
    const ruleset = viewEventData.event?.ruleset.value;

    if (ruleset === "Major v1.0") {
      history.push(`/events/edit/event/majorv1/${eventId}`);
    } else if (ruleset === "Local v1.0") {
      history.push(`/events/edit/event/localv1/${eventId}`);
    } else if (ruleset === "LOS v1.0") {
      history.push(`/events/edit/event/losv1/${eventId}`);
    } else {
      history.push("/");
    }
  };

  if (isLoading && eventInfo) {
    return (
      <div className={styles.loader}>
        <div>
          <Loader />
        </div>
        <div className={styles.loaderText}>Loading View LOS V1.0</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.pageHeader}>
            <Header
              title={eventInfo?.marketing?.title}
              onClick={() => history.goBack()}
            />
          </div>

          <div className={styles.buttonContainer}>
            <Button
              className={styles.editBtn}
              children={"EDIT"}
              onClick={handleEditEvent}
            />
          </div>
        </div>

        <div className={styles.pageContent}>
          {eventInfo && (
            <div className={styles.cardContainer}>
              <EventTypeCard eventInfo={eventInfo.event} />
            </div>
          )}

          <div className={styles.cardContainer}>
            {eventInfo && (
              <EventV1InfoForm marketingInfo={eventInfo} pageMode="view" />
            )}
          </div>
          <div className={styles.cardContainer}>
            {eventInfo && (
              <PublishingV1InfoForm
                publishingInfo={eventInfo}
                pageMode="view"
              />
            )}
          </div>

          <div className={styles.cardContainer}>
            {eventInfo?.event?.dates && (
              <DateV1Form
                dateInfo={eventInfo?.event}
                location={eventInfo?.event?.location?.value}
                ruleset={eventInfo?.event?.ruleset?.value}
                eventName={eventInfo?.event?.name?.value}
                pageMode="view"
              />
            )}
          </div>

          {eventInfo?.event?.location?.value === "onsite" && (
            <div className={styles.cardContainer}>
              {eventInfo?.venue && (
                <VenueV1Form venueInfo={eventInfo} pageMode="view" />
              )}
            </div>
          )}

          <div className={styles.cardContainer}>
            {eventInfo && (
              <TicketingV1Form
                ticketGroups={[
                  { name: "Ticket Group Name", tickets: eventInfo?.tickets },
                ]}
                setTicketGroups={() => {}}
                eventName={eventInfo?.event?.name?.value}
              />
            )}
          </div>

          <div className={styles.cardContainer}>
            {eventInfo && <NotesV1Form notesData={eventInfo} pageMode="view" />}
          </div>
        </div>
      </div>
    </>
  );
}
