import { useQuery, useMutation } from "react-query";
import { Auth } from "shared/@auth/Auth";

// Exporting venue options hook separately
export function useVenueOptionsGet(ruleset = "all") {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, status, isLoading, refetch } = useQuery(
    ["venueOptions", ruleset],
    () =>
      makeAuthedRequest(`v1/events/venues/options/${ruleset}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      staleTime: 0,
    }
  );

  return {
    data,
    status,
    refetch,
    isLoading,
  };
}

// Exporting the main venues management hook
export function useManageVenuesPage(params) {
  const { makeAuthedRequest } = Auth.useContainer();
  const { ruleset, stateId, city, search, sort, page, limit } = params; // Destructure params object

  const fetchVenues = async () => {
    const response = await makeAuthedRequest(
      `v1/events/venues/ruleset/${ruleset}/stateId/${stateId}/city/${city}/search/${search}/sort/${sort}/page/${page}/limit/${limit}`,
      {
        method: "GET",
      }
    );

    if (response.ok) {
      return response.json();
    } else {
      const body = await response.json();
      throw new Error(body.message);
    }
  };

  const { data, status, isLoading, refetch } = useQuery(
    ["venues", params], // Use params as the query key
    fetchVenues,
    {
      onError: (error) => {
        console.log("Error fetching venues:", error.message.message);
      },
    }
  );

  const resolveAddress = useMutation((resolveAddressData) =>
    makeAuthedRequest(`v1/events/venues/resolve-address`, {
      method: "POST",
      body: JSON.stringify(resolveAddressData),
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const createVenue = useMutation((newVenueData) =>
    makeAuthedRequest(`v1/events/venues`, {
      method: "POST",
      body: JSON.stringify(newVenueData),
    }).then(async (res) => {
      if (res.ok) {
        return res.status;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const updateVenue = useMutation((updatedVenueData) =>
    makeAuthedRequest(`v1/events/venues`, {
      method: "PUT",
      body: JSON.stringify(updatedVenueData),
    }).then(async (res) => {
      if (res.ok) {
        return res.status;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const archiveVenue = useMutation((venueToArchiveData) =>
    makeAuthedRequest(`v1/events/venues/archive`, {
      method: "POST",
      body: JSON.stringify(venueToArchiveData),
    }).then(async (res) => {
      if (res.ok) {
        return res.status;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  return {
    data,
    status,
    isLoading,
    refetch,
    resolveAddress,
    createVenue,
    updateVenue,
    archiveVenue,
  };
}
