import React, { useState } from "react"; // Import useState
import PropTypes from "prop-types";
import styles from "./RefundOrderConfirmationModal.module.css";
import { Modal } from "shared/Modal";
import classNames from "classnames";
import Loader from "shared/Loader";

/**
 * Modal that opens when the user clicks the refund button for an order.
 */

export function RefundOrderConfirmationModal({
  isOpen,
  onCollapse,
  onClose,
  onClick,
  isLoading,
  refundCompleted,
  refundMessage,
  refundMessageType,
  titleType = 1,
}) {
  const [applyFee, setApplyFee] = useState(false);
  const alertClasses = {
    success: "success", // These should match your CSS classes for alerts
    error: "danger",
  };

  const renderRefundMessage = () => {
    // Dynamically setting class names based on the message type
    const alertClass =
      refundMessageType === "success" ? styles.success : styles.danger; // Adjust to use CSS module references

    return (
      <div className={alertClass} role="alert">
        {refundMessage}
      </div>
    );
  };

  const handleRefundClick = () => {
    onClick(applyFee);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onCollapse();
    }
  };

  return (
    <div className={styles.container}>
      <Modal
        title={
          titleType == 1 ? "REFUND CONFIRMATION" : "CANCELLATION CONFIRMATION"
        }
        isOpen={isOpen}
        onClose={handleClose}
        className={styles.modal}
      >
        <>
          {isLoading ? (
            <Loader />
          ) : refundCompleted ? (
            <div>{refundMessage && renderRefundMessage()}</div>
          ) : (
            <>
              {titleType == 1 && (
                <>
                  <label className={styles.label}>
                    Apply a cancellation fee:
                  </label>
                  <input
                    type="checkbox"
                    checked={applyFee}
                    onChange={() => setApplyFee(!applyFee)}
                  />
                </>
              )}
              <div className={styles.confirm}>
                Are you sure you want to {titleType == 1 ? "refund" : "cancel"}{" "}
                this order?
              </div>
              <div>
                <button
                  className={classNames(styles.button, styles.refundBtn)}
                  onClick={handleRefundClick}
                >
                  {titleType == 1 ? "REFUND" : "CANCEL"}
                </button>
                <button className={styles.button} onClick={onCollapse}>
                  CLOSE
                </button>
              </div>
            </>
          )}
        </>
      </Modal>
    </div>
  );
}

RefundOrderConfirmationModal.propTypes = {
  /**
   * Determines if the modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * Function called when cancel is clicked
   */
  onCollapse: PropTypes.func,
  /**
   * Function called when refund is clicked
   */
  onClick: PropTypes.func,
};
