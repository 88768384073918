import React, { useState, useCallback } from "react";
import styles from "./ReportsPage.module.css";
import Loader from "shared/Loader";
import RunReports from "Events/Components/RunReports";

export function ReportsPage() {
  const [isLoading, setIsLoading] = useState(false);

  const eventTypeOptions = [
    "Spring Leadership",
    "Free Enterprise",
    "Leadership Summit",
    "Family Reunion",
    "Summer Conference",
  ];

  const eventOptions = ["Event1", "Event2", "Event3", "Event4", "Event5"];

  const yearOptions = ["2020", "2021", "2022", "2023", "2024"];

  const reportOptions = [
    "Geographical",
    "ADA",
    "Tickets Sold",
    "Platinum",
    "Eagle",
    " Host Diamond",
  ];

  const [eventSelectOptions, setEventSelectOptions] = useState(eventOptions);

  //EventType onChange select
  const handleEventTypeChange = (selectedEventType) => {
    if (selectedEventType) {
      setEventSelectOptions(eventOptions);
    } else {
      setEventSelectOptions(eventOptions);
    }
  };

  //Handles run onclick button
  const handleRunReports = () => {
    alert("Run Reports Clicked");
  };

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <div>
          <Loader />
        </div>
        <div className={styles.loaderText}>Loading Reports</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.headerContainer}>
            <h1 className={styles.header}>REPORTS</h1>
          </div>

          <div className={styles.selectContainer}>
            <div className={styles.selectDiv}>
              <RunReports
                eventTypeOptions={eventTypeOptions}
                eventOptions={eventOptions}
                yearOptions={yearOptions}
                reportOptions={reportOptions}
                onRun={handleRunReports}
                onEvenTypeChange={handleEventTypeChange}
              />
            </div>
          </div>

          <div className={styles.reportsContainer}>
            <div className={styles.report}>Reports Data </div>
          </div>
        </div>
      </div>
    </>
  );
}
