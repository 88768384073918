import React, { useContext, createContext, useState, useEffect } from "react";
import { Auth } from "shared/@auth/Auth";
import { useQuery } from "react-query";

export const CurrentRouteContext = createContext();

export function useEventStatesGet() {
  const { makeAuthedRequest, isAuthenticated } = Auth.useContainer();

  const fetchStates = async () => {
    const cacheKey = "statesData";
    const cachedData = sessionStorage.getItem(cacheKey);

    // Try to return cached data if available
    if (cachedData) {
      return JSON.parse(cachedData);
    }

    // Fetch from API if no cache is available
    const response = await makeAuthedRequest(`v1/events/states/all`, {
      method: "GET",
    });
    if (response.ok) {
      const data = await response.json();
      // Cache the fetched data
      sessionStorage.setItem(cacheKey, JSON.stringify(data));
      return data;
    } else {
      const body = await response.json();
      throw new Error(body.message);
    }
  };

  const { data, isError, isLoading, refetch } = useQuery(
    ["state"],
    fetchStates,
    {
      enabled: isAuthenticated,
    }
  );

  return { data, refetch, isError, isLoading };
}

export function useViewRulesets() {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, status, isLoading, refetch } = useQuery(
    ["rulesets"],
    () =>
      makeAuthedRequest(`v1/events/rulesets/all`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      staleTime: 0,
    }
  );

  return {
    data,
    status,
    refetch,
    isLoading,
  };
}

export const CurrentRouteProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState("");

  const { data, isError, isLoading, refetch } = useEventStatesGet();
  const {
    data: rulesetData,
    status: rulesetStatus,
    refetch: refetchRulesetData,
    isLoading: isRulesetDataLoading,
  } = useViewRulesets();

  return (
    <CurrentRouteContext.Provider
      value={{
        currentRoute,
        setCurrentRoute,
        data,
        isError,
        isLoading,
        refetch,

        rulesetData,
        rulesetStatus,
        refetchRulesetData,
        isRulesetDataLoading,
      }}
    >
      {children}
    </CurrentRouteContext.Provider>
  );
};

export function useCurrentRoute() {
  return useContext(CurrentRouteContext);
}
