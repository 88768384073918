import React, { useEffect, useState } from "react";
import styles from "./NoteField.module.css";
import PropTypes from "prop-types";
import editSvg from "./edit.svg";

export function NoteField({ initialText, onSave }) {
  const [isEditing, setIsEditing] = useState(false);
  const [noteText, setNoteText] = useState(initialText);
  useEffect(() => {
    setNoteText(initialText);
  }, [initialText]);

  return (
    <>
      {isEditing ? (
        <div className={styles.noteFieldContainer}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              placeholder="Enter Note Here..."
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              className={styles.noteInput}
            />
          </div>
          <div className={styles.buttonsContainer}>
            <button
              className={styles.cancelButton}
              onClick={() => {
                setNoteText(initialText);
                setIsEditing(false);
              }}
            >
              Cancel
            </button>
            <button
              className={styles.saveButton}
              onClick={() => {
                setIsEditing(false);
                onSave(noteText);
              }}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.noteViewContainer}>
          <div className={styles.note}>{noteText}</div>
          <button
            onClick={() => setIsEditing(true)}
            className={styles.noteBtnContainer}
          >
            <div className={styles.noteEditBtn}>
              <img src={editSvg} className={styles.noteBtnImage} />

              <div className={styles.noteBtnText}>{noteText ? "Edit Note" : "Add Note"}</div>
            </div>
          </button>
        </div>
      )}
    </>
  );
}

NoteField.propTypes = {
  text: PropTypes.string,
  setText: PropTypes.func,
  onSave: PropTypes.func,
};
