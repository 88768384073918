import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./UndoChangesModal.module.css";
import { Modal } from "shared/Modal";
import DeleteModal from "Events/Components/DeleteModal";

/**
 * Modal that opens when clicking undo changes button in edit events page
 */
export function UndoChangesModal({
  editedEvent,
  isOpen,
  onCollapse,
  onClick,
  className,
}) {
  function onClose() {
    onCollapse();
  }

  return (
    <>
      <Modal
        title="CHANGES"
        isOpen={isOpen}
        onClose={onClose}
        closeBtn="none"
        className={`${styles.modal} ${className}`}
        children={
          <>
            <div className={styles.container}>
              <div className={styles.header}>
                <div>Field</div>
                <div>Original Value</div>
                <div>New Saved Value</div>
              </div>
              <div className={styles.hr} />

              <div className={styles.itemContainer}>
                {editedEvent &&
                  editedEvent.map((change, index) =>
                    change.original_value != change.saved_value ? (
                      <div key={index} className={styles.item}>
                        <div className={styles.field}> {change.field}</div>
                        <div> {change.original_value}</div>
                        <div>{change.saved_value}</div>

                        <button
                          className={styles.undoBtn}
                          onClick={() => {
                            onClick(
                              index,
                              change.path,
                              change.field_name, // Used to update the data
                              change.original_value,
                              change.field, // Displays in Undo Confirmation modal
                            );
                          }}
                        >
                          UNDO
                        </button>
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </div>

              <div className={styles.hr} />
              <div className={styles.button}>
                <button className={styles.closeBtn} onClick={onClose}>
                  CLOSE
                </button>
              </div>
            </div>
          </>
        }
      />
    </>
  );
}

UndoChangesModal.propTypes = {
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  eventChanges: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      original_value: PropTypes.string,
      saved_value: PropTypes.string,
    })
  ),
};

UndoChangesModal.defaultProps = {
  eventChanges: [],
};
