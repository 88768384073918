import { useQuery, useQueryClient, useMutation } from "react-query";
import { Auth } from "shared/@auth/Auth";
import { useHistory } from "react-router-dom";
import { useManageEventsPage } from "Events/Pages/ManageEventsPage/useManageEventsPage";

export function useImportEvents({ eventType, year }) {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();

  // Function to fetch events
  const fetchImportEvents = async () => {
    const res = await makeAuthedRequest(
      `v1/events/import/${eventType}/events/${year}`,
      { method: "GET" }
    );
    if (!res.ok) {
      // Handle error scenario
      const error = await res.json();
      throw new Error(error.message.message);
    }
    const data = await res.json();
    return data;
  };

  // useQuery to fetch events
  const {
    data: eventsData,
    status,
    isLoading,
    error,
  } = useQuery(
    ["importEvents", { eventType, year }],
    () => fetchImportEvents(),
    {
      enabled: !!eventType && !!year,
    }
  );

  return {
    eventsData,
    status,
    isLoading,
    error,
  };
}
export function useImportPost() {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();
  const history = useHistory(); // React Router v5 hook for navigation

  // Function to import an event
  const postImportEvent = async ({ eventType, eventId }) => {
    if (!eventType || !eventId) return; // Ensure eventType and eventId are provided
    const response = await makeAuthedRequest(
      `v1/events/import/${eventType}/event/${eventId}`,
      {
        method: "POST",
      }
    );
    if (!response.ok) {
      const errorBody = await response.json();
      throw new Error(errorBody.message);
    }
    return response.json();
  };
  const {
    data: importData,
    mutate: importEvent,
    isLoading: isImporting,
    error: importError,
  } = useMutation(postImportEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries("events");
      queryClient.refetchQueries("events", { active: true });

      // queryClient.invalidateQueries("settings");
    },
  });

  return {
    importData,
    importEvent,
    isImporting,
    importError,
  };
}
