import React, { useState } from "react";
import { Select } from "../../../shared/Select/Select";
import { SearchBar } from "../../../shared/SearchBar/SearchBar";
import { Button } from "../../../shared/Button/Button";
import styles from "./FilterRuleset.module.css";
import PropTypes from "prop-types";

/**
 * A component that can be used to filter events by rulesets
 * and event name.
 */

export function FilterRuleset({ onClick, rulesetData, eventOptions }) {
  const [filterData, changeFilterData] = useState({
    ruleset: "All",
    event: "All",
    search: "",
  });

  const handleRulesetSelection = (selection) => {
    changeFilterData((prevData) => ({
      ...prevData,
      ruleset: selection,
      event: "All", // Reset event when ruleset changes
    }));
  };

  const handleEventSelection = (selection) => {
    changeFilterData((prevData) => ({
      ...prevData,
      event: selection,
    }));
  };

  const handleSearchInput = (value) => {
    changeFilterData((prevData) => ({
      ...prevData,
      search: value,
    }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <label className={styles.label}>Ruleset</label>
        <Select
          options={Object.keys(rulesetData)}
          placeholder={filterData.ruleset} // Shows current selection or "All" by default
          onChange={(option) => handleRulesetSelection(option)}
        />
      </div>
      <div className={styles.select}>
        <label className={styles.label}>Event Name</label>
        <Select
          options={eventOptions}
          placeholder={filterData.event} // Shows current selection or "All" by default
          onChange={(option) => handleEventSelection(option)}
        />
      </div>
      <div className={styles.search}>
        <SearchBar
          placeholderText="Click to type"
          minChar={0}
          onInput={handleSearchInput}
        />
      </div>
      <div className={styles.button}>
        <Button
          className={styles.updateBtn}
          children="UPDATE"
          onClick={() => onClick(filterData)} // Passes the updated filter data on click
        />
      </div>
    </div>
  );
}

FilterRuleset.propTypes = {
  // function to be called when selecting a dropdown filter item
  onClick: PropTypes.func.isRequired,
  rulesetData: PropTypes.object.isRequired,
  eventOptions: PropTypes.array.isRequired,
};
