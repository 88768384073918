import React from "react";
import PropTypes from "prop-types";
import styles from "./AddressInfoItem.module.css";

export function AddressInfoItem({
  address,
  labelColor,
  isInModal,
  isSuggested,
}) {
  const { recipient, line1, line2, city, state, postalCode, country } = address;

  const labelStyle = {
    color: labelColor,
    fontWeight: 500,
  };

  const getUpdatedState = (state) => {
    if (state?.includes(" - ")) {
      return state.split(" - ")[0];
    }
    return state?.substring(0, 2)?.toUpperCase();
  };

  const updatedState = getUpdatedState(state);

  const capitalizeFirstLetter = (string) =>
    string?.toLowerCase().replace(/\b\w/g, (c) => c?.toUpperCase());

  const formatPostalCode = (postalCode) => postalCode?.slice(0, 5) || "";

  return (
    <div className={styles.container}>
      {!isInModal && <div style={labelStyle}>{recipient}</div>}
      <div className={styles.addressValue}>
        <div>
          {isSuggested ? line1?.toUpperCase() : capitalizeFirstLetter(line1)}
        </div>
        <div>
          {isSuggested ? line2?.toUpperCase() : capitalizeFirstLetter(line2)}
        </div>
        {/* <div>{line1}</div>
        <div>{line2}</div> */}
        <div>
          {isSuggested ? city?.toUpperCase() : capitalizeFirstLetter(city)},{" "}
          {updatedState}{" "}
          {/* {!isInModal || !isSuggested
            ? formatPostalCode(postalCode)
            : postalCode} */}
          {postalCode}
        </div>
        {isInModal && <div>{country}</div>}
      </div>
    </div>
  );
}

AddressInfoItem.propTypes = {
  address: PropTypes.shape({
    recipient: PropTypes.string,
    country: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  labelColor: PropTypes.string,
};
