import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import TicketGroup from "Events/Components/TicketGroup";
import styles from "./TicketingV1Form.module.css";

/**Area to fill out ticketing information  */
export function TicketingV1Form({ ticketGroups, setTicketGroups, eventName }) {
  //fill state and state functions on initial render
  useEffect(() => {
    let limit = [];
    let available = [];
    let ticketsPer = [];
    let tickets = [];

    ticketGroups.forEach((group, index) => {
      limit[index] = group.limit;
      available[index] = group.available;
      ticketsPer[index] = group.ticketsPer;

      // Conditionally update the ticket price
      // tickets[index] = group.tickets.map((ticket) => {
      //   if (ticket.name === "Standard" || ticket.name === "Standard Platinum") {
      //     return { ...ticket, price: 215 }; // Set price to 215 for specific ticket names
      //   }
      // return ticket; // Leave other tickets unchanged
      // });
    });

    setLimit(limit);
    setAvailable(available);
    setTicketsPer(ticketsPer);
    updateTickets(tickets);
  }, []);

  //Local states populated from the global ticketgroups state
  const [limit, setLimit] = useState([]);
  const handleLimit = (value, index) => {
    let _limit = [...limit];
    _limit[index] = value;
    setLimit(_limit);
  };

  const [available, setAvailable] = useState([]);
  const handleAvailable = (value, index) => {
    let _available = [...available];
    _available[index] = value;
    setAvailable(_available);
  };

  const [ticketsPer, setTicketsPer] = useState([]);
  const handleTicketsPer = (value, index) => {
    let _ticketsPer = [...ticketsPer];
    _ticketsPer[index] = value;
    setTicketsPer(_ticketsPer);
  };

  const [tickets, updateTickets] = useState([]);
  const handleTickets = (value, index) => {
    let _tickets = [...tickets];
    _tickets[index] = value;
    updateTickets(_tickets);
  };

  //useEffect blocks for updating the ticketgroups on local state change
  useEffect(() => {
    let _ticketGroups = [...ticketGroups];
    _ticketGroups.forEach((ticket, index) => {
      _ticketGroups[index].limit = limit[index];
    });
    setTicketGroups(_ticketGroups);
  }, [limit]);

  useEffect(() => {
    let _ticketGroups = [...ticketGroups];
    _ticketGroups.forEach((ticket, index) => {
      _ticketGroups[index].available = available[index];
    });
    setTicketGroups(_ticketGroups);
  }, [available]);

  useEffect(() => {
    let _ticketGroups = [...ticketGroups];
    _ticketGroups.forEach((ticket, index) => {
      _ticketGroups[index].tickets = tickets[index];
    });
    setTicketGroups(_ticketGroups);
  }, [tickets]);

  useEffect(() => {
    let _ticketGroups = [...ticketGroups];
    _ticketGroups.forEach((ticket, index) => {
      _ticketGroups[index].available = available[index];
    });
    setTicketGroups(_ticketGroups);
  }, [available]);

  return (
    <Card
      children={
        <section>
          <div className={styles.bold}>TICKETING</div>
          <hr className={styles.hr} />
          <div className={styles.ticketGroup}>
            {ticketGroups.map((group, index) => {
              const filteredTickets =
                eventName === "Second Look Meeting"
                  ? group.tickets.filter((ticket) => ticket.name !== "Seminar")
                  : group.tickets;

              return (
                <TicketGroup
                  key={index}
                  name={group.name}
                  tickets={filteredTickets}
                  isMajorV1={true}
                  isV2={false}
                />
              );
            })}
          </div>
        </section>
      }
    />
  );
}

TicketingV1Form.propTypes = {
  /**Array of ticket group objects (name-String, available-Number, limit-String, ticketsPer-string, tickets-Array)  */
  ticketGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      available: PropTypes.number,
      setAvailable: PropTypes.func,
      limit: PropTypes.string,
      setLimit: PropTypes.shape({}),
      ticketsPer: PropTypes.string,
      setTicketsPer: PropTypes.shape({}),
      tickets: PropTypes.array,
      updateTickets: PropTypes.shape({}),
      eventStartDate: PropTypes.string,
    })
  ),
  /**Function that changes the ticket groups */
  setTicketGroups: PropTypes.func,
};
