import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ConfirmSpeakerModal.module.css";
import classNames from "classnames";
import { Modal } from "shared/Modal";
import { RadioButtonList } from "Events/Components/RadioButtonList/RadioButtonList";
import Button from "shared/Button";

/**
 * When adding a new speaker on one of the create new event pages the user must confirm the speaker first.
 * This modal is displayed after the add button is clicked in the add speaker modal.
 */
export function ConfirmSpeakerModal({
  IBO,
  selectedSpeakers,
  isOpen,
  onCollapse,
  onClick,
}) {
  const [selection, setSelection] = useState([]);

  var namesString = "";
  var options = [];
  var lastOptionString = "";
  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  // building the string for the Name row and last radio button option, just adding ", " or ", and " and whatnot
  for (var i = 0; i < IBO.names?.length || 0; i++) {
    namesString += IBO.names[i];
    lastOptionString += "IBO " + letters[i];

    if (IBO.names?.length > 2) {
      if (i < IBO.names.length - 2) {
        namesString += ", ";
        lastOptionString += ", ";
      } else if (i < IBO.names.length - 1) {
        namesString += ", and ";
        lastOptionString += ", & ";
      }
    } else if (i < IBO.names.length - 1) {
      namesString += " and ";
      lastOptionString += " & ";
    }

    options.push(IBO.names[i] + " (IBO " + letters[i] + ")");
  }
  if (IBO.names?.length > 1) {
    if (IBO.names?.length == 2) {
      options.push("Both (" + lastOptionString + ")");
    } else {
      options.push("All (" + lastOptionString + ")");
    }
  }

  const handleSelection = (selection) => {
    //If the last option was the one selected
    if (selection === options[options.length - 1] && options.length > 1) {
      const names = getAllNamesFromRadioOptions();
      setSelection(names);
    } else {
      setSelection(getNameFromOption(selection));
    }
  };

  const getAllNamesFromRadioOptions = () =>
    options
      .filter((o, index) => {
        //Filter out the last option ("All of the above")
        if (index !== options.length - 1) {
          return true;
        }
        return false;
      })
      .map((element) => getNameFromOption(element));

  const getNameFromOption = (option) =>
    option
      //split at parens, which outputs an array of [name , ibo info]
      .split("(")
      //remove the ibo info entirely
      .filter((op, ind) => ind % 2 === 0)
      //flatten to one array
      .flat()
      //trim trailing spaces
      .map((name) => name.trim());

  return (
    <>
      <Modal
        // title="Confirm Speaker(s):"
        title={"CONFIRM SPEAKER(S):"}
        isOpen={isOpen}
        onClose={onCollapse}
        closeBtn={onCollapse}
        className={styles.modal}
        children={
          <>
            <div className={styles.grid}>
              <div className={styles.bold}>Name</div>
              <div className={styles.item}>{namesString}</div>

              <div className={styles.bold}>Username</div>
              <div className={styles.item}>{IBO.userName}</div>

              <div className={styles.bold}>IBO Number</div>
              <div className={styles.item2}>{IBO.iboNum}</div>

              <div className={styles.bold}>Email</div>
              <div className={styles.item2}>{IBO.email}</div>
            </div>

            <div className={styles.hr} />

            <div className={classNames(styles.bold, styles.selectTitle)}>
              SELECT:
            </div>
            <div className={styles.radioButtonListContainer}>
              <RadioButtonList onClick={handleSelection} options={options} />
            </div>
            {selection.length > 0 && (
              <div className={styles.submitButton}>
                <Button
                  children="ADD SPEAKER"
                  onClick={() => onClick(selection)}
                />
              </div>
            )}
          </>
        }
      />
    </>
  );
}

ConfirmSpeakerModal.propTypes = {
  IBO: PropTypes.object,
  selectedSpeakers: PropTypes.array,
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  onClick: PropTypes.func,
};

ConfirmSpeakerModal.defaultProps = {
  IBO: {
    names: [],
  },
  selectedSpeakers: [],
};
