import styles from "./Auth.module.css";
import React, { useState, useCallback, useEffect } from "react";
import { createContainer } from "unstated-next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as MSAL from "@azure/msal-browser";

import apiPath from "shared/@utils/apiPath";

import _hasPermission from "./hasPermission";
import InitLoading from "./InitLoading";
import AccountLoading from "./AccountLoading";
import { useLogger } from "shared/@hooks/useLogger";

export * from "./useAuthedRequest";

/**
 * Determine the redirect URI for MSAL dynamically based on the domain the app is running on.
 */
const redirectUri = (() => {
  if (window.location.hostname.includes("localhost")) {
    return "http://localhost:3000";
  } else {
    return `https://${window.location.hostname}`;
  }
})();

/**
 * Initialize MSAL client to handle auth through Azure AD.
 */
const msal = new MSAL.PublicClientApplication({
  auth: {
    clientId:
      window.location.hostname === "events.wwg.support" ||
      window.location.hostname === "localhost"
        ? "0c190b37-1c8d-411f-9c11-1e3e2f3a30a4"
        : "fafaf9cc-a6d3-44ba-aef3-ca7481f2268a",
    authority:
      "https://login.microsoftonline.com/ab6e2cd2-57a9-49a0-9222-c75f356d143c",
    redirectUri,
    cache: {
      cacheLocation: "localStorage", // set your cache location to local storage
    },
  },
});

/**
 * Provides functions for signing in, signing out, making authenticated requests and more.
 */
export function useAuth() {
  const { logError } = useLogger({ prefix: "Auth" });
  const [isInitialized, setIsInitialized] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [account, setAccount] = useState(null);
  const [permissions, setPermissions] = useState(null);

  const signIn = useCallback(async () => {
    setErrorMessage(null);

    try {
      const res = await msal.loginRedirect({});
      setAccount(res.account);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const signOut = useCallback(async () => {
    setAccount(null);
    msal.logoutRedirect();
  }, []);

  const getToken = useCallback(
    async (scopes = []) => {
      const req = {
        scopes:
          scopes.length > 0 ? scopes : ["api://wwg.support.v2.api/api.access"],
        account,
      };

      return msal
        .acquireTokenSilent(req)
        .then((res) => res.accessToken)
        .catch(async (err) => {
          if (err instanceof MSAL.InteractionRequiredAuthError) {
            return msal.acquireTokenPopup(req);
          } else {
            throw err;
          }
        });
    },
    [account]
  );

  /**
   * Make a fetch request with a prefilled auth token.
   * Config object is passed on to fetch() instance.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
   */
  const makeAuthedRequest = useCallback(
    async (path, config = {}, tokenScopes = []) => {
      return getToken(tokenScopes).then((token) => {
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };

        if (config.headers) {
          for (const key in config.headers) {
            if (config.headers[key] == null) {
              // Delete the default value if present with a null or undefined value.
              delete headers[key];
            } else {
              headers[key] = config.headers[key];
            }
          }
        }

        return fetch(apiPath(path), { ...config, headers });
      });
    },
    [getToken]
  );

  /**
   * Checks the logged in user's permission list for a match.
   * Supports complete keys as well as wildcards.
   */
  const hasPermission = useCallback(
    (key) => _hasPermission(key, permissions),
    [permissions]
  );

  const getPermissions = useCallback(async () => {
    let tryAgain = false;

    try {
      const res = await makeAuthedRequest("v1/core/permissions/current");

      if (res.ok) {
        return res.json();
      } else {
        if (res.status === 503) {
          tryAgain = true;

          throw new Error(
            `Failed to look up app permissions. The Azure Function may still be warming up. Trying again.`
          );
        } else if (res.status === 500) {
          tryAgain = true;

          throw new Error(
            `Failed to look up app permissions. There seems to be a problem with the backend. Trying again.`
          );
        } else {
          tryAgain = false;

          throw new Error(
            `Failed to look up app permissions. An unknown error has occurred.`
          );
        }
      }
    } catch (err) {
      logError(err.message);

      if (tryAgain) {
        return getPermissions();
      }
    }

    return makeAuthedRequest("v1/core/permissions/current")
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            `Failed to look up app permissions. The Azure Function may still be warming up. Trying again.`
          );
        }
      })
      .then(setPermissions)
      .catch(logError);
  }, [logError]);

  // Handle auth through MSAL on mount.
  useEffect(() => {
    let wasLoggedIn = false;

    msal
      .handleRedirectPromise()
      .then((res) => {
        if (res && res.account) {
          setAccount(res.account);
          wasLoggedIn = true;
        }

        // If res !== null, you are coming back from a successful authentication redirect.
        // If res === null, you are not coming back from an auth redirect.
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!wasLoggedIn) {
          const accounts = msal.getAllAccounts();

          if (accounts.length === 1) {
            setAccount(accounts[0]);
          } else if (accounts.length > 1) {
            console.warn("More than one account", accounts);
          }
        }

        setIsInitialized(true);
      });
  }, []);

  // Fetch current user's permissions list on login.
  useEffect(() => {
    if (account != null) {
      makeAuthedRequest("v1/core/permissions/current")
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            setErrorMessage(
              "Failed to look up permissions. The Azure Function may still be warming up. Please try again."
            );
          }
        })
        .then(setPermissions)
        .catch(console.error);
    }
  }, [account, makeAuthedRequest]);

  return {
    account,
    permissions,
    hasPermission,
    isInitialized,
    isSignedIn: !!account,
    isReady: !!account && !!permissions && errorMessage == null,
    errorMessage,
    signIn,
    signOut,
    getToken,
    makeAuthedRequest,
  };
}

/**
 * Exported as an unstated container for sharing auth state between components.
 * Provider must be mounted higher in the component tree in order to use the container
 * from inside a component.
 */
export const Auth = createContainer(useAuth);

/**
 * Wraps children in an auth provider that shows a login screen when unauthenticated
 * and the child content when authenticated.
 */
export function AuthProvider({ children }) {
  return (
    <Auth.Provider>
      <AuthContainer>{children}</AuthContainer>
    </Auth.Provider>
  );
}

function AuthContainer({ children }) {
  const { isInitialized, isSignedIn, isReady, signIn, errorMessage } =
    Auth.useContainer();

  // Make sure this matches CSS transition speeds.
  const transSpeed = 80;

  let content;

  if (!isInitialized) {
    // Currently checking if user is logged in.
    content = (
      <CSSTransition
        key="notInitialized"
        timeout={transSpeed}
        classNames={styles}
      >
        <div className={styles.container}>
          <InitLoading />
        </div>
      </CSSTransition>
    );
  } else if (!isSignedIn) {
    signIn();
    // Not logged in. Show the login page.
    content = (
      <CSSTransition key="notSignedIn" timeout={transSpeed} classNames={styles}>
        <div className={styles.container}>
          <div className={styles.signInActions}>
            <svg
              className={styles.signInLogo}
              fill="none"
              height="800"
              viewBox="0 0 1024 800"
              width="1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="#fff">
                <path d="m24.4599 86.3913v-25.6003h281.6041v25.6003s-37.338 3.6161-51.201 22.4007c-13.358 18.099-6.4 51.2-6.4 51.2l179.202 483.207-51.2 108.801h-32.001l-227.203-592.008s-16.164-36.154-35.2003-51.2c-18.9348-14.967-57.6008-22.4007-57.6008-22.4007z" />
                <path d="m357.264 86.3913v-25.6003h281.604v25.6003s-37.337 3.6161-51.201 22.4007c-13.357 18.099-6.4 51.2-6.4 51.2l179.203 483.207-51.201 108.801h-32.001l-227.203-592.008s-16.164-36.154-35.2-51.2c-18.935-14.967-57.601-22.4007-57.601-22.4007z" />
                <path d="m786.274 58.4017-60.061 20.7847 71.628 29.6026 36.866-37.6677z" />
                <path d="m840.771 73.6274-36.108 37.9816 95.883 39.627 3.136-51.608z" />
                <path d="m907.746 154.212 2-52.078 43.66 25.342 28.242 57.279z" />
                <path d="m789.984 258.783-66.669-172.5849 71.628 29.6029z" />
                <path d="m897.648 158.248-95.883-39.627-4.778 148.985z" />
                <path d="m807.038 265.831 97.811-104.607 73.902 30.543z" />
              </g>
            </svg>

            <AccountLoading />
          </div>
        </div>
      </CSSTransition>
    );
  } else if (errorMessage) {
  } else if (!isReady) {
    // Logged in and waiting for permissions list.
    content = (
      <CSSTransition key="notReady" timeout={transSpeed} classNames={styles}>
        <div className={styles.container}>
          <AccountLoading />
        </div>
      </CSSTransition>
    );
  } else {
    // Logged in and loaded. Render the children.
    content = (
      <CSSTransition key="ready" timeout={transSpeed} classNames={styles}>
        <div className={styles.children}>{children}</div>
      </CSSTransition>
    );
  }

  return (
    <TransitionGroup className={styles.transitionGroup}>
      {content}
    </TransitionGroup>
  );
}
