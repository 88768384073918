import React, { useState, useEffect, useCallback, useMemo } from "react";

import styles from "./ArchivedEventsPage.module.css";
import { EventCard } from "Events/Components/EventCard/EventCard";
import PageSelect from "Events/Components/PageSelect";
import classNames from "classnames";
import FilterMenu from "Events/Components/FilterMenu";
import NewEventModal from "Events/Components/NewEventModal";
import { useHistory } from "react-router-dom";
import { useArchivedEventsPage, useSettings } from "./useArchivedEventsPage";
import formatDate from "shared/@utils/formatDate";
import Loader from "shared/Loader";
import CloneModal from "Events/Components/CloneModal";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";

export function ArchivedEventsPage() {
  const history = useHistory();

  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    settingsPut,
    refetch: refetchSettings,
  } = useSettings();
  const {
    rulesetData,
    rulesetStatus,
    refetchRulesetData,
    isRulesetDataLoading,
  } = useCurrentRoute();

  const [viewCountLimit, setViewCountLimit] = useState("10");
  const [shouldFetch, setShouldFetch] = useState(false);

  //Check archiedView first before fetching data
  useEffect(() => {
    if (isLoadingSettings) {
      setShouldFetch(false);
    } else if (
      settingsData &&
      settingsData[0] &&
      settingsData[0].archivedView
    ) {
      const archivedLimit = settingsData[0].archivedView;

      setViewCountLimit(archivedLimit);
      setShouldFetch(true);
    } else {
      setShouldFetch(true);
    }
  }, [isLoadingSettings, settingsData]);

  const {
    data: archivedData,
    cloneEvent,
    isLoading: isLoadingEvents,
    status,
    refetch,
  } = useArchivedEventsPage({
    viewCountLimit,
    shouldFetch,
  });

  const [settings, setSettings] = useState({
    dateRange: "",
    eventName: "",
    ruleset: "",
    venue: "",
    search: "",
  });

  const [filteredEvents, setFilteredEvents] = useState(null);
  const [filterObj, setFilterObj] = useState(undefined);
  const [selectedRuleset, setSelectedRuleset] = useState(null);
  const [currentEvent, setCurrentEvent] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [createNewOpened, setCreateNewOpened] = useState(false);
  const [cloneOpened, setCloneOpened] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [initialFilteringDone, setInitialFilteringDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleOptionsDropdown(option) {
    // eslint-disable-next-line default-case
    switch (option) {
      case "Manage Venues":
        history.push("/events/manage-venues");
        break;
      case "View Rulesets":
        history.push("/events/view/rulesets");
        break;
    }
  }

  useEffect(() => {
    if (archivedData) {
      setFilteredEvents(archivedData);
    }
  }, [archivedData, viewCountLimit]);

  const [hasInitialFilteringBeenDone, setHasInitialFilteringBeenDone] =
    useState(false);

  useEffect(() => {
    const applyInitialFilters = async () => {
      if (hasInitialFilteringBeenDone || !archivedData) {
        return;
      }

      let initialFilters;
      if (
        settingsData?.length > 0 &&
        settingsData[0].hasOwnProperty("archivedFilterBy")
      ) {
        initialFilters = settingsData[0].archivedFilterBy;

        if (initialFilters.venue === "onsite") {
          initialFilters.venue = "Onsite";
        } else if (initialFilters.venue === "virtual") {
          initialFilters.venue = "Virtual";
        }
      } else {
        initialFilters = settings;
      }

      try {
        await handleFilter(initialFilters);
        setInitialFilteringDone(true);

        setHasInitialFilteringBeenDone(true);
      } catch (error) {
        console.error("Failed to apply filters:", error);
      }
    };

    applyInitialFilters();
  }, [settingsData, archivedData, hasInitialFilteringBeenDone]);

  useEffect(() => {
    if (archivedData && initialFilteringDone) {
      handleFilter(filterObj);
    }
  }, [viewCountLimit, filterObj, archivedData, initialFilteringDone]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [filters, setFilters] = useState({});
  useEffect(() => {
    if (
      settingsData &&
      settingsData[0].archivedFilterBy &&
      settingsData[0].archivedFilterBy.ruleset
    ) {
      setSelectedRuleset(settingsData[0].archivedFilterBy.ruleset);
    }
  }, [settingsData]);

  // Filter Options
  const rulesetOptions = useMemo(() => {
    return [
      "Select",
      ...new Set((rulesetData || []).map((ruleset) => ruleset.name)),
    ];
  }, [archivedData, rulesetData]);

  const eventNameOptions = useMemo(() => {
    // If the selectedRuleset is "Select" or if no ruleset is selected, display all event names
    if (!selectedRuleset || selectedRuleset === "Select") {
      let return1 = [
        "Select",
        ...new Set(
          (rulesetData || []).flatMap((item) =>
            item.events.map((event) => event.name)
          )
        ),
      ];
      return return1;
    }

    // If a specific ruleset is selected, display only the events for that ruleset
    return [
      "Select",
      ...new Set(
        (rulesetData || [])
          .filter((ruleset) => ruleset.name === selectedRuleset)
          .flatMap((item) => item.events.map((event) => event.name))
      ),
    ];
  }, [archivedData, selectedRuleset]);

  const eventVenueLocationOptions = useMemo(
    () => ["Select", "Onsite", "Virtual"],
    [archivedData]
  );

  const eventStatusOptions = useMemo(
    () => ["Select", "Published", "Unpublished", "Archived"],
    [archivedData]
  );

  const updateSettings = useCallback(
    (newSettings) => {
      setIsLoading(true);

      const defaultArchivedFilterBy = {
        ruleset: null,
        eventName: null,
        venue: null,
        dateRange: null,
        search: null,
      };

      const isValidDateRange = (range) => {
        const dateRangePattern =
          /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)\/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)$/;
        return dateRangePattern.test(range);
      };

      const updatedArchivedFilterBy = {
        ...defaultArchivedFilterBy,
        ...newSettings.archivedFilterBy,
        venue: newSettings.archivedFilterBy?.venue?.toLowerCase() || null,
        dateRange:
          newSettings.archivedFilterBy.dateRange &&
          isValidDateRange(newSettings.archivedFilterBy.dateRange)
            ? newSettings.archivedFilterBy.dateRange
            : null,
      };

      const settingsDataToUse = {
        ...newSettings,
        archivedFilterBy: updatedArchivedFilterBy,
      };

      settingsPut(settingsDataToUse)
        .then((updatedSettings) => {
          setSettings(updatedSettings);
          setForceUpdate((prev) => !prev);
          refetchSettings().then(() =>
            refetch().then(() => setIsLoading(false))
          );
        })
        .catch((error) => {
          console.error("Error updating settings:", error);
          setIsLoading(false);
        });
    },
    [settingsPut]
  );

  const handleFilter = useCallback(
    async (filterObject) => {
      if (!archivedData) {
        return;
      }
      let filteredData = archivedData;
      setFilterObj(filterObject);
      setFilteredEvents(filteredData);
    },
    [archivedData]
  );

  const handleViewCountLimitChange = async (newLimit) => {
    setIsLoading(true);
    setViewCountLimit(newLimit);

    const defaultArchivedFilterBy = {
      ruleset: null,
      eventName: null,
      venue: null,
      dateRange: null,
      search: null,
    };

    const isValidDateRange = (range) => {
      const dateRangePattern =
        /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)\/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)$/;
      return dateRangePattern.test(range);
    };

    const updatedArchivedFilterBy = {
      ...defaultArchivedFilterBy,
      ...filters.archivedFilterBy,
      venue: filters.archivedFilterBy?.venue?.toLowerCase() || null,
      dateRange:
        filters.archivedFilterBy.dateRange &&
        isValidDateRange(filters.archivedFilterBy.dateRange)
          ? filters.archivedFilterBy.dateRange
          : null,
    };

    const updatedSettings = {
      ...settingsData[0],
      archivedView: newLimit,
      archivedFilterBy: updatedArchivedFilterBy,
    };

    try {
      await settingsPut(updatedSettings);
      await refetchSettings();
      await refetch();
    } catch (error) {
      console.error("Failed to update view count limit in settings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Cloning event
  function handleOpenClone(event) {
    setCurrentEvent(event);
    setCloneOpened(true);
    setOpenOptions(false);
  }

  const handleClone = async () => {
    const event = JSON.parse(JSON.stringify(currentEvent));

    const newClonedEvent = await cloneEvent.mutateAsync({
      eventId: currentEvent._id,
      id: event._id,
      ruleset: event.ruleset,
    });

    // Check if the event's ruleset is 'majorv 1.0' and redirect accordingly
    if (currentEvent.event.ruleset.value === "Major v1.0") {
      history.push(`/events/edit/event/majorv1/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "Local v1.0") {
      history.push(`/events/edit/event/localv1/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "LOS v1.0") {
      history.push(`/events/edit/event/losv1/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "Major v2.0") {
      history.push(`/events/edit/event/majorv2/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "Local v2.0") {
      history.push(`/events/edit/event/localv2/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "LOS v2.0") {
      history.push(`/events/edit/event/losv2/${newClonedEvent.id}`);
    } else {
      history.push(`/events/edit/${newClonedEvent.id}`);
    }
  };

  if (
    isLoadingEvents ||
    isLoadingSettings ||
    isRulesetDataLoading ||
    !initialFilteringDone ||
    isLoading
  ) {
    return (
      <div className={styles.loader}>
        <div>
          <Loader />
        </div>
        <div className={styles.loaderText}>Loading Archived Events</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {(isLoadingEvents && isLoadingSettings) || !initialFilteringDone ? (
          <div className={styles.loader}>
            <div>
              <Loader />
            </div>
            <div className={styles.loaderText}>Loading Archived Events</div>
          </div>
        ) : (
          <>
            <div className={styles.subContainer}>
              <div className={styles.headerContainer}>
                <h1 className={styles.header}>ARCHIVED EVENTS</h1>

                <div className={styles.optionSelectContainer}>
                  <PageSelect
                    disabled={false}
                    placeholder="OPTIONS"
                    options={["Manage Venues", "View Rulesets"]}
                    onChange={(option) => handleOptionsDropdown(option)}
                  />
                </div>
              </div>

              <div className={styles.filterAndNewContainer}>
                <div className={styles.filterByText}>Filter By</div>
                <div className={styles.filterDiv}>
                  <FilterMenu
                    handleFilter={(filterObject) => handleFilter(filterObject)}
                    onRulesetChange={setSelectedRuleset}
                    venueOptions={eventVenueLocationOptions}
                    eventNameOptions={eventNameOptions}
                    eventStatusOptions={eventStatusOptions}
                    rulesetOptions={rulesetOptions}
                    dataPresent={archivedData && archivedData.length > 0}
                    settings={settingsData}
                    settingsPut={updateSettings}
                    contextIdentifier="archivedEvents"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </div>
              </div>

              <div className={styles.viewCountContainer}>
                <div>VIEW</div>
                <div
                  className={classNames(
                    styles.viewCountItem,
                    !isLoadingEvents &&
                      viewCountLimit === "10" &&
                      styles.underline
                  )}
                  onClick={() => handleViewCountLimitChange("10")}
                >
                  10
                </div>
                <div
                  className={classNames(
                    styles.viewCountItem,
                    !isLoadingEvents &&
                      viewCountLimit === "25" &&
                      styles.underline
                  )}
                  onClick={() => handleViewCountLimitChange("25")}
                >
                  25
                </div>
                <div
                  className={classNames(
                    styles.viewCountItem,
                    !isLoadingEvents &&
                      viewCountLimit === "50" &&
                      styles.underline
                  )}
                  onClick={() => handleViewCountLimitChange("50")}
                >
                  50
                </div>
                <div
                  className={classNames(
                    styles.viewCountItem,
                    !isLoadingEvents &&
                      viewCountLimit === "all" &&
                      styles.underline
                  )}
                  onClick={() => handleViewCountLimitChange("all")}
                >
                  All
                </div>
              </div>

              <div className={styles.eventsGrid}>
                <div className={styles.gridItem}></div>
                <div className={styles.gridItem}>EVENT</div>
                <div className={styles.gridItem}>TICKETS SOLD</div>
                <div className={styles.gridItem}>STATUS</div>
                <div className={styles.gridItem}></div>
              </div>
            </div>

            {/* {isLoading || !initialFilteringDone ? (
          <Loader />
        ) :  */}
            {filteredEvents && filteredEvents.length > 0 ? (
              filteredEvents.map((item, index) => (
                <div className={styles.eventCard} key={index}>
                  <EventCard
                    onView={() => {
                      history.push(`/events/view/${item.id}`, {
                        eventDetails: item,
                      });
                    }}
                    onClone={() => {
                      handleOpenClone(item);
                    }}
                    event={{
                      image: item.event.icon.value ? item.event.icon.value : "",
                      name: item.event.name.value ? item.event.name.value : "",
                      title: item.marketing?.title?.value || "",

                      startDate: item.event.dates?.eventStartDate?.value || "",

                      endDate: item.event.dates?.eventEndDate?.value || "",

                      jobCode:
                        item.event.jobCode && item.event.jobCode.value
                          ? item.event.jobCode.value
                          : "",
                      ticketsSold:
                        item.analytics &&
                        item.analytics.sales &&
                        item.analytics.sales.sold
                          ? item.analytics.sales.sold.value
                          : NaN,
                      totalTickets:
                        item.analytics &&
                        item.analytics.sales &&
                        item.analytics.sales.capacity
                          ? item.analytics.sales.capacity.value
                          : NaN,
                      status: item.event.status.value
                        ? item.event.status.value
                        : "",
                      ruleset:
                        item.event.ruleset && item.event.ruleset.value
                          ? item.event.ruleset.value
                          : "",
                      id: item._id,
                      venue: item.event.location.value,
                    }}
                  />
                </div>
              ))
            ) : (
              <div className={styles.noEventsMessage}>
                No Archived Events Found.
              </div>
            )}

            <NewEventModal
              isOpen={createNewOpened}
              onCollapse={() => setCreateNewOpened(false)}
            />

            <CloneModal
              eventName={
                currentEvent &&
                currentEvent.event &&
                currentEvent.event.name.value
                  ? currentEvent.event.name.value
                  : ""
              }
              ruleset={
                currentEvent &&
                currentEvent.event &&
                currentEvent.event.ruleset.value
                  ? currentEvent.event.ruleset.value
                  : ""
              }
              location={
                currentEvent &&
                currentEvent.event &&
                currentEvent.event.location.value
                  ? capitalizeFirstLetter(currentEvent.event.location.value)
                  : ""
              }
              isOpen={cloneOpened}
              onCollapse={() => setCloneOpened(false)}
              onClick={handleClone}
            />
          </>
        )}
      </div>
    </>
  );
}

function dateCheck(from, to, check) {
  if (!check) return false; // Return false if check date is missing

  var fDate = Date.parse(from);
  var lDate = Date.parse(to);
  var cDate = Date.parse(check);

  if (isNaN(fDate) || isNaN(lDate) || isNaN(cDate)) return false; // Return false if any date is invalid

  return cDate <= lDate && cDate >= fDate;
}
