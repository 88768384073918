import React, { useState, useEffect } from "react";
import styles from "./DatePicker.module.css";
import { ReactComponent as Calendar } from "./calendar.svg";
import PropTypes from "prop-types";
import moment from "moment";
import { CalendarPickerModal } from "Events/Components/CalendarPickerModal/CalendarPickerModal";

export function DatePicker({ dateString, setDate, onChange, onBlur }) {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [refreshDateString, setRefreshDateString] = useState(0);

  useEffect(() => {
    if (dateString) {
      const isValidDate = moment(dateString, "MM/DD/YYYY", true).isValid();

      if (
        isValidDate &&
        inputValue !== moment(dateString).format("MM/DD/YYYY")
      ) {
        setInputValue(moment(dateString).format("MM/DD/YYYY"));
      }
    } else {
      setInputValue("");
    }
  }, [dateString, refreshDateString]);

  const handleInputChange = (event) => {
    const rawValue = event.target.value;
    let numericValue = rawValue.replace(/\D/g, "");

    // Dynamically format the input as MM/DD/YYYY
    if (numericValue.length > 2) {
      numericValue =
        numericValue.substring(0, 2) + "/" + numericValue.substring(2);
    }
    if (numericValue.length > 4) {
      numericValue =
        numericValue.substring(0, 5) + "/" + numericValue.substring(5);
    }

    setInputValue(numericValue);

    // Only update the state if the input is a complete and valid date
    if (
      numericValue.length === 10 &&
      moment(numericValue, "MM/DD/YYYY", true).isValid()
    ) {
      setDate(numericValue); //
      onChange && onChange(numericValue);
    }
  };

  const handleFocus = () => {
    if (moment(inputValue, "MM/DD/YYYY", true).isValid()) {
      setInputValue("");
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.icon}>
          <Calendar height="1.2em" onClick={() => setIsOpen(!isOpen)} />
        </div>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          className={styles.input}
          placeholder="MM/DD/YYYY"
          onBlur={() => setRefreshDateString(refreshDateString + 1)}
        />
      </div>

      <CalendarPickerModal
        isOpen={isOpen}
        onCollapse={() => setIsOpen(false)}
        allowPastDate={true}
        onDateSelect={(date) => {
          const formattedDate = moment(date).format("MM/DD/YYYY");
          setInputValue(formattedDate);
          setDate(formattedDate);
          setIsOpen(false);
          onChange && onChange(formattedDate);
        }}
      />
    </div>
  );
}

DatePicker.propTypes = {
  dateString: PropTypes.string,
  setDate: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

CalendarPickerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  allowPastDate: PropTypes.bool,
  onDateSelect: PropTypes.func.isRequired,
};
